/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/


:root {
	/* Global variables */
	--background-color : #1c1b22;
	--background-color-darker: #151419;
}


body {
	background-color : #1c1b22;
}

* {
color: darkgrey;
}

/* quick uses of flex on elements */
.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-center {
	justify-content: center;
	align-items:center;
	align-content:center;
	text-align:center;
}

.flex-column-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

/* add or remove to dynamically show elements */
.hidden {
	display: none !important;
}


h1 {
	font-size: 3em;
}

.center-div {
	margin: auto;
	padding: auto;
	width: 100%;
	text-align: center;
}

/*.center-div > * { margin-bottom: 50px; }*/

img {
	padding: auto;
	margin: auto;
	
	object-fit: contain;
	height:100%;

	/*display: block;*/
}


#index-slideshow {
	/*max-width: 500px;
	max-height: 500px;*/
	width: 100%;
	height: 90vh;
	max-height:90vh;

	word-wrap: break-word;
	overflow: hidden;
}

