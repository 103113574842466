.slideshow-component {
    position: relative;
    background-color: var(--background-color-darker);

    * {
        max-width:  inherit;
        max-height: inherit;
    }
}

.slideshow-content {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items:center;
    justify-content: center;
}

.controls-arrow {
    /* Appearance */
    font-family: monospace;
    font-size: 30px;
        /* For some reason you need to do this otherwise the text has opacity separate from the parent */
        background: transparent;

    /* positioning */
    position: absolute;
    z-index: 99;
    top: 50%;

    &.left {
        left:0;
    }
    &.right {
        right:0;
    }

    /* Mouse behavior */
    cursor: pointer;
    &:hover {
        color: white;
    }
}

.slideshow-controls-play-pause {
    /* For some reason you need to do this otherwise the text has opacity separate from the parent */
    background: transparent;

    font-family: monospace;
    font-size: 30px;
    
    cursor: pointer;


    &:hover {
        color: white;
    }

}

